import { Helmet } from 'react-helmet-async';
import { Box, styled, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCustomer
} from 'src/redux/actions/sysAdminActions';

const MainContainer = styled(Box)(
  () => `
  padding: 0px 32px 0px 32px;
  `
);

/* identical to box height */
async function LoadCustomerData(){    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [time, setTime] = useState({
      seconds: 0,
      minutes: 0,
      hours: 0
    });
    const authToken = useSelector((state) => state?.auth?.token?.token);
  
    const authUser = useSelector((state) => state?.auth?.authUserData);
    const customerData = useSelector((state) => state?.customers?.customerData);

    if (authUser && authUser?.roles?.find((d) => d?.code === 'ROLE_CUSTOMER')) {      
        await dispatch(getCustomer(authUser?.associatedCustomerId, authToken));
        navigate(`/customer-portal/${id}/products?cp=true`);
    } else {
        await dispatch(getCustomer(id, authToken));
        navigate(`/customer-portal/${id}/products?cp=true`);
    }
    
    
}

function LoadCustomerPortalMain() {
  
  LoadCustomerData();

  return (
    <>
      <MainContainer>
      </MainContainer>
    </>
  );
}

export default LoadCustomerPortalMain;
