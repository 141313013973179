import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback
  } from 'react';
import {
  Box,
  styled,
  Grid,
  Typography,
  TextField,
  Button,
  TableContainer,
  Card,
  CardContent
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import SERVER_BASE_URL from 'src/config/config';
import moment from 'moment';

import DataTable from 'src/components/DataTables/DataTable';
import $ from 'jquery';

const MainContainer = styled(Box)(
  () => `
  padding: 0px 32px 0px 32px;
  `
);

const ModalButton = styled(Button)(
  () => `
  background-color: #15364a !important;
    color: white !important;
    height: 40px !important;
    border-radius: 3px !important;
    padding: 0 13px !important;
    font-weight: 600;
    font-size: 16px;

    :hover {
        background-color: black !important;
      }
    `
);



const AutomatedOrderImportTable = ({ userID }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const authToken = useSelector((state) => state?.auth?.token?.token);
    const customerData = useSelector((state) => state?.customers?.customerData);
    const authUser = useSelector((state) => state?.auth?.authUserData);
    const [time, setTime] = useState({
      seconds: 0,
      minutes: 0,
      hours: 0
    });

    
    const [automatedOrderImportDataTableColumns, setAutomatedOrderImportDataTableColumns] = useState(false);
    const [automatedOrderImportDataTableOptions, setAutomatedOrderImportDataTableOptions] = useState(false);
    const [automatedOrderImportDataTableData, setAutomatedOrderImportDataTableData] = useState(false);

    const gridStyle = useMemo(
      () => ({ height: '100%', width: '100%', margin: '0 0 12px 0' }),
      []
    );

    useEffect(() => {
      navigate('?cp=true');
    }, []);

    useEffect(() => {
      if (authUser && authUser?.roles?.find((d) => d?.code === 'ROLE_CUSTOMER')) {
        let isCancelled = false;
  
        advanceTime(time, isCancelled, setTime, 'Base Feature');
  
        return () => {
          isCancelled = true;
          localStorage.setItem('componentTime', JSON.stringify(time));
        };
      }
    }, [time]);
  
    useEffect(() => {
      return () => {
        const value = JSON.parse(localStorage.getItem('componentTime'));
  
        if (value?.seconds > 59) {
          dispatch(
            timeCalculation(
              authUser?.email,
              'Base Feature',
              value?.seconds,
              authUser?.id
            )
          );
        }
  
        localStorage.clear();
      };
    }, []);

    useEffect(() => {
      if (authUser && authUser?.roles?.find((d) => d?.code === 'ROLE_CUSTOMER')) {
        window.addEventListener('beforeunload', handleUnload);
        return () => {
          window.removeEventListener('beforeunload', handleUnload);
        };
      }
    }, []);

    function handleUnload(event) {
      event.preventDefault();
      event.returnValue = '';
  
      // Make API call here
      const value = JSON.parse(localStorage.getItem('componentTime'));
      if (value?.seconds > 59) {
        dispatch(
          timeCalculation(
            authUser?.email,
            'Base Feature',
            value?.seconds,
            authUser?.id
          )
        );
        localStorage.clear();
      }
    };

    const handlePartNumberLink = (event) => {
      let row = $(event.target).data();
      let linkData = {
        partNumber: row.partnumber,
        partName: row.desc,
        id: row.id,
      };
  
      navigate(
        row.link,
        {
          state: linkData
        }
      )
    };

    //Data Table settings
    useEffect(() => {
      let columns = [
        {
          title: 'Part Number',
          name: 'partnumber',
          data: 'partNumber',
        },
        {
          title: 'Product',
          name: 'product',
          data: 'partDescription',
        },
        {
          title: 'Order Number',
          name: 'ordernumber',
          data: 'salesOrderNumber',
        },
        {
          title: 'PO Number',
          name: 'ponumber',
          data: 'customerOrderNumber',
        },
        {
          title: 'Quantity',
          name: 'quantity',
          data: 'quantity',
          render: function(data, type, row, meta) {
            return data + ' ' + row.uom;
          },
        },
        {
          title: 'Due Date',
          name: 'duedate',
          data: 'invoiceDate',
          render: function(data){
            return moment(data, 'YYYY-MM-DD').format('MM/DD/YYYY');
          }
        },
        {
          title: 'Status',
          name: 'status',
          defaultContent: '',
        },
        {
          title: 'Documents',
          name: 'documents',
          defaultContent: '',
          render: function(data, type, row, meta) {
            let linkArray = [];
            if(row.invoiceFilePath.length > 0) {
              linkArray.push(`<a href="#" data-type="INVOICE">Invoice</a>`);
            }
            if(row.mtrFilePath.length > 0) {
              linkArray.push(`<a href="#" data-type="MTR">MTR</a>`);
            }
            if(row.packingListFilePath.length > 0) {
              linkArray.push(`<a href="#" data-type="PACKAGING_LIST">Packing List</a>`);
            }
            if(row.podFilePath.length > 0) {
              linkArray.push(`<a href="#" data-type="POD">POD</a>`);
            }
            return linkArray.join(' | ');
          }
        }
      ];

      setAutomatedOrderImportDataTableColumns(columns);
    }, []);

    //Set up Buttons based on privileges
    const AutomatedOrderImportDataTableButtons = () => {
      let buttons = [];
      buttons.push(
        {extend: 'copy',  className: 'btn-outline-secondary'}, 
        {extend: 'csv',   className: 'btn-outline-secondary'},
        {extend: 'print', className: 'btn-outline-secondary'}
        );
      return buttons;
    };
    

    useEffect(() => {
      let options = {
          dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
                "<'row'<'col-12'tr>>" +
                "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
          buttons: AutomatedOrderImportDataTableButtons(),
          ajax: {
            url: `${SERVER_BASE_URL}/product/${customerData?.customerNumber}/OrderHistory`,
            type: "GET",
            dataSrc: function(data) {
              setAutomatedOrderImportDataTableData(data.content);
              return data.content;
            },
            // dataSrc: 'content',
            contentType: 'application/json',
            data: {
                pageIndex: 0,
                pageSize: 99999,
                sortOrder: '',
                search: ''
              },
            dataType: 'JSON',
            timeout: 0,
            headers: {
              "Authorization": `Bearer ${authToken}`,
            },
          },
          info: true,
          processing: true,
          retrieve: true,
          pageResize: true,
          scrollCollapse: true,
          lengthChange: true,
          pageLength: 10,
          fixedHeader: true,
          scrollY: '450px',
          order: [[1,'asc']],
          searching: true,
          language: {
            search: '_INPUT_',
            searchPlaceholder: 'Search',
          }
      };
      setAutomatedOrderImportDataTableOptions(options);
    }, []);

    return (
      <MainContainer>
        <div className="custom-pg-content-sec">
          <Box
            className="custom-pg-titlesticky-sec"
            component="form"
            sx={{
              '& > :not(style)': { ml: 0 },
              display: { md: 'flex', xs: 'box' },
              flexDirection: 'row'
            }}
            noValidate
            autoComplete="off"
          >
            <Box className="custom-heading-container-sec" sx={{}}>
              <Breadcrumb
                className="custom-heading-sec"
                userData={customerData}
                location={[
                  {
                    location: 'Home',
                    to: `/customer-portal/${customerData?.id}/products`
                  }
                ]}
                page={'Automated Order Import'}
              />
              <label
                style={{
                  fontSize: '15px',
                  fontWeight: '600',
                  lineHeight: '1',
                  marginTop: '12px'
                }}
              >
                Auto Order Import
              </label>
            </Box>          
          </Box>
          {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
          <div className="custom-pg-tables-content-sec">
            <TableContainer style={gridStyle}>
              {automatedOrderImportDataTableColumns && automatedOrderImportDataTableOptions && (
              <DataTable 
                id={'AutomatedOrderImportTable'}
                columns={automatedOrderImportDataTableColumns}
                options={automatedOrderImportDataTableOptions}
                onRowClick={handlePartNumberLink}
              />
              )}
            </TableContainer>
          </div>
        </div>
      </MainContainer>
    );
};

export default AutomatedOrderImportTable;

  