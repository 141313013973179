import { useContext, useMemo } from 'react';

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem
} from '@mui/material';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { SidebarContext } from '../../../../contexts/SidebarContext';

import HomeIcon from '../../../../assets/Icons/Home_inactive.svg';
import HomeActive from '../../../../assets/Icons/Home_active.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';



import Vector_active from '../../../../assets/Icons/Vector_active.svg';
import user_inactive from '../../../../assets/Icons/user_inactive.svg';
import UserIcon from '../../../../assets/Icons/Customer_inactive.svg';
import UserActive from '../../../../assets/Icons/Customer_active.svg';
import MonitorIcon from '../../../../assets/Icons/Monitor.png';
import MonitorActive from '../../../../assets/Icons/PC_active.svg';
import SupplierIcon from '../../../../assets/Icons/icon1.svg';
import SupplierActive from '../../../../assets/Icons/Icon1_active.svg';
import POChangeRequestIcon from '../../../../assets/Icons/icon4.svg';
import POChangeRequestActive from '../../../../assets/Icons/Icon4_active.svg';

import PcActive from '../../../../assets/Icons/PC_active.svg';

import ManageUserActive from '../../../../assets/Icons/Users_active.svg';
import ManageUserIcon from '../../../../assets/Icons/Users_inactive.svg';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding-top: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme?.colors?.alpha?.trueWhite[50]};
      // padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme?.colors?.primary?.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme?.colors?.alpha?.trueWhite[100]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme?.colors?.alpha?.white};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme?.colors?.alpha?.black};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          & {
            
      border-left: transparent solid 3px;
          }

          &.active
           {
            background-color: #FFFFFF;
            border-left: #20A2F3 solid 3px;
            color: #20A2F3;
            border-radius: unset;

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme?.colors?.alpha?.trueWhite[0]};
            }
          }
          
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme?.colors?.alpha?.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const IconHolder = styled(Box)(
  () => `
      margin: 0px 15px 0px 15px;

`
);

function SidebarMenu(props) {
  const { closeSidebar, Active } = useContext(SidebarContext);

  const menuActive = useSelector((state) => state?.dashboard?.getMenuActive);

  const authUserData = useSelector((state) => state?.auth?.authUserData);
  const customerData = useSelector((state) => state?.customers?.customerData);
  const permissions = useSelector((state) => state?.auth?.token?.authorities);
  const supplierData = useSelector((state) => state?.supplier?.supplierData);
  const userType = authUserData?.roles?.some((d) => d?.id === 1 || d?.id === 2);

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const hasPermission = (permission) => {
    return permissions?.permissions?.some(
      (userPermission) => userPermission === permission
    );
  };

  // const isCustomer = permissions?.roles?.
  let query = useQuery();

  const showCustomerPortalSideMenus = () => {
    return (
      <>
        {query.get('cp') === 'true' && (
          <>
            {authUserData?.features?.find((d) => d?.code === 'BF') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Products" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(10)}
                    to={`/customer-portal/${authUserData?.id}/products?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'box'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}
            {authUserData?.features?.find((d) => d?.code === 'BF') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Order History" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(4)}
                    to={`/customer-portal/${authUserData?.id}/orderHistory?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'scroll'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}
            {authUserData?.features?.find((d) => d?.code === 'BCS') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Barcode Scanner" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(5)}
                    to={`/customer-portal/${customerData?.id}/BarCodeScanner?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'barcode'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}
            {authUserData?.features?.find((d) => d?.code === 'BO') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Bulk Order" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(6)}
                    to={`/customer-portal/${authUserData?.id}/bulkOrder?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'boxes-stacked'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}

            {authUserData?.features?.find((d) => d?.code === 'BPO') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Blanket PO" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(7)}
                    to={`/customer-portal/${authUserData?.id}/blanketPo?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'receipt'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}

            {authUserData?.features?.find((d) => d?.code === 'BPO') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Blanket PO View" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(7)}
                    to={`/customer-portal/${authUserData?.id}/blanket-po-view?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'file-invoice'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}

            {authUserData?.features?.find((d) => d?.code === 'BPO') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Auto Order Import" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(7)}
                    to={`/customer-portal/${authUserData?.id}/auto-order-import?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'file-arrow-up'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}

            {authUserData?.features?.find((d) => d?.code === 'FC') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Forecast" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(8)}
                    to={`/customer-portal/${authUserData?.id}/forecast?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'chart-line'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}

            {authUserData?.features?.find((d) => d?.code === 'RMD') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>   
                <Tooltip title="Raw Material Depletion" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(9)}
                    to={`/raw-material-list/${customerData?.customerNumber}?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'pallet'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}

            {authUserData?.features?.find((d) => d?.code === 'FGD') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>   
                <Tooltip title="Finished Good Depletion" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(11)}
                    to={`/finished-good-depletion/${customerData?.id}?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'cart-flatbed'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}
          </>
        )}
      </>
    );
  };

  const showSideMenus = () => {
    return (
      <>
        {query.get('cp') === 'true' && (
          <>
            {customerData?.features?.find((d) => d?.featureCode === 'BF') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Products" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(10)}
                    to={`/customer-portal/${customerData?.id}/products?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'box'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}
            {customerData?.features?.find((d) => d?.featureCode === 'BF') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Order History" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(4)}
                    to={`/customer-portal/${customerData?.id}/orderHistory?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'scroll'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}
            {authUserData?.features?.find((d) => d?.featureCode === 'BCS') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Barcode Scanner" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(5)}
                    to={`/customer-portal/${customerData?.id}/BarCodeScanner?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'barcode'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}
            {customerData?.features?.find((d) => d?.featureCode === 'BO') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Bulk Order" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(6)}
                    to={`/customer-portal/${customerData?.id}/bulkOrder?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'boxes-stacked'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}

            {customerData?.features?.find((d) => d?.featureCode === 'BPO') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Blanket PO" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(7)}
                    to={`/customer-portal/${customerData?.id}/blanketPo?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'receipt'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}

            {customerData?.features?.find((d) => d?.featureCode === 'BPO') && ( 
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Blanket PO View" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(7)}
                    to={`/customer-portal/${customerData?.id}/blanket-po-view?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'file-invoice'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}

            {customerData?.features?.find((d) => d?.featureCode === 'BPO') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Auto Order Import" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(7)}
                    to={`/customer-portal/${customerData?.id}/auto-order-import?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'file-arrow-up'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}

            {customerData?.features?.find((d) => d?.featureCode === 'FC') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Forecast" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(8)}
                    to={`/customer-portal/${customerData?.id}/forecast?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'chart-line'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}

            {customerData?.features?.find((d) => d?.featureCode === 'RMD') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>   
                <Tooltip title="Raw Material Depletion" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(9)}
                    to={`/raw-material-list/${customerData?.customerNumber}?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'pallet'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}

            {customerData?.features?.find((d) => d?.featureCode === 'FGD') && (
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>   
                <Tooltip title="Finished Good Depletion" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(11)}
                    to={`/finished-good-depletion/${customerData?.id}?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'cart-flatbed'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}

            {customerData?.features?.find((d) => d?.featureCode === 'CPE') && ( 
              <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                <Tooltip title="Customer Parts Edit" disableInteractive>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={() => closeSidebar(12)}
                    to={`/customer-portal/${authUserData?.id}/EditParts?cp=true`}
                    startIcon={
                      <IconHolder>
                        <FontAwesomeIcon icon={icon({name: 'screwdriver-wrench'})} />
                      </IconHolder>
                    }
                  ></Button>
                </Tooltip>
              </ListItem>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <MenuWrapper>
        <List
          component="div"
          sx={{ padding: '0px' }}
          subheader={
            <ListSubheader component="div" disableSticky></ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List sx={{ padding: '0px' }}>
              {hasPermission('Dashboard') && (
                <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                  <Tooltip title="Home" arrow>
                    <Button
                      disableRipple
                      component={RouterLink}
                      to={'/portal'}
                      startIcon={
                        <IconHolder>
                          <FontAwesomeIcon icon={icon({name: 'home'})} />
                        </IconHolder>
                      }
                    ></Button>
                  </Tooltip>
                </ListItem>
              )}

              {hasPermission('ManageUsers') && (
                <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                  <Tooltip title="Manage Users" arrow>
                    <Button
                      disableRipple
                      component={RouterLink}
                      to="/manage-users"
                      startIcon={
                        <IconHolder>
                          <FontAwesomeIcon icon={icon({name: 'user'})} />
                        </IconHolder>
                      }
                    ></Button>
                  </Tooltip>
                </ListItem>
              )}

              {hasPermission('ManageRoles') && (
                <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                  <Tooltip title="Manage Roles" arrow>
                    <Button
                      disableRipple
                      component={RouterLink}
                      to="/manage-roles"
                      startIcon={
                        <IconHolder>
                          <FontAwesomeIcon icon={icon({name: 'pen-to-square'})} />
                        </IconHolder>
                      }
                    ></Button>
                  </Tooltip>
                </ListItem>
              )}

              {hasPermission('ManageFeatures') && (
                <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                  <Tooltip title="Manage Features" arrow>
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={() => closeSidebar(3)}
                      to={'/manage-features'}
                      startIcon={
                        <IconHolder>
                          <FontAwesomeIcon icon={icon({name: 'star'})} />
                        </IconHolder>
                      }
                    ></Button>
                  </Tooltip>
                </ListItem>
              )}
              {authUserData && authUserData?.roles?.some((d) => d?.id === 4) && (
                <>
                  {authUserData?.features?.find(
                    (d) => d?.featureCode === 'BF'
                  ) && (
                    <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                      <Tooltip title="Products" disableInteractive>
                        <Button
                          disableRipple
                          component={RouterLink}
                          onClick={() => closeSidebar(10)}
                          to={`/customer-portal/${customerData?.id}/products?cp=true`}
                          startIcon={
                            <IconHolder>
                              <FontAwesomeIcon icon={icon({name: 'box'})} /> 
                            </IconHolder>
                          }
                        ></Button>
                      </Tooltip>
                    </ListItem>
                  )}
                  {query.get('cp') === 'true' && (
                    <>
                      {authUserData?.features?.find(
                        (d) => d?.featureCode === 'BF'
                      ) && (
                        <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                          <Tooltip title="Order History" disableInteractive>
                            <Button
                              disableRipple
                              component={RouterLink}
                              onClick={() => closeSidebar(4)}
                              to={`/customer-portal/${customerData?.id}/orderHistory?cp=true`}
                              startIcon={
                                <FontAwesomeIcon icon={icon({name: 'scroll'})} />
                              }
                            ></Button>
                          </Tooltip>
                        </ListItem>
                      )}
                      {authUserData?.features?.find(
                        (d) => d?.featureCode === 'BCS'
                      ) && (
                        <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                          <Tooltip title="Barcode Scanner" disableInteractive>
                            <Button
                              disableRipple
                              component={RouterLink}
                              onClick={() => closeSidebar(5)}
                              to={`/customer-portal/${customerData?.id}/BarCodeScanner?cp=true`}
                              startIcon={
                                <IconHolder>
                                  <FontAwesomeIcon icon={icon({name: 'barcode'})} />
                                </IconHolder>
                              }
                            ></Button>
                          </Tooltip>
                        </ListItem>
                      )}
                      {authUserData?.features?.find(
                        (d) => d?.featureCode === 'BO'
                      ) && (
                        <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                          <Tooltip title="Bulk Order" disableInteractive>
                            <Button
                              disableRipple
                              component={RouterLink}
                              onClick={() => closeSidebar(6)}
                              to={`/customer-portal/${authUserData?.associateCustomerId}/bulkOrder?cp=true`}
                              startIcon={
                                <IconHolder>
                                  <FontAwesomeIcon icon={icon({name: 'boxes-stacked'})} />
                                </IconHolder>
                              }
                            ></Button>
                          </Tooltip>
                        </ListItem>
                      )}

                      {authUserData?.features?.find(
                        (d) => d?.featureCode === 'BPO'
                      ) && (
                        <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                          <Tooltip title="Blanket PO" disableInteractive>
                            <Button
                              disableRipple
                              component={RouterLink}
                              onClick={() => closeSidebar(7)}
                              to={`/customer-portal/${authUserData?.associateCustomerId}/blanketPo?cp=true`}
                              startIcon={
                                <IconHolder>
                                  <FontAwesomeIcon icon={icon({name: 'receipt'})} />
                                </IconHolder>
                              }
                            ></Button>
                          </Tooltip>
                        </ListItem>
                      )}

                      {authUserData?.features?.find(
                        (d) => d?.featureCode === 'FC'
                      ) && (
                        <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                          <Tooltip title="Forecast" disableInteractive>
                            <Button
                              disableRipple
                              component={RouterLink}
                              onClick={() => closeSidebar(8)}
                              to={`/customer-portal/${authUserData?.associateCustomerId}/forecast?cp=true`}
                              startIcon={
                                <IconHolder>
                                  <FontAwesomeIcon icon={icon({name: 'chart-line'})} />
                                </IconHolder>
                              }
                            ></Button>
                          </Tooltip>
                        </ListItem>
                      )}
                    </>
                  )}
                </>
              )}

              {hasPermission('ManageCustomers') && (
                <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                  <Tooltip title="Manage Customers" arrow>
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={() => closeSidebar(3)}
                      to={'/manage-customers'}
                      startIcon={
                        <IconHolder>
                          <FontAwesomeIcon icon={icon({name: 'people-group'})} />
                        </IconHolder>
                      }
                    ></Button>
                  </Tooltip>
                </ListItem>
              )}

              {hasPermission('ManageSalesCustomers') && (
                <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                  <Tooltip title="Customer List" arrow>
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={() => closeSidebar(9)}
                      to="/manage-sales-customers?cp=true"
                      startIcon={
                        <IconHolder>
                          <FontAwesomeIcon icon={icon({name: 'people-carry-box'})} />
                        </IconHolder>
                      }
                    ></Button>
                  </Tooltip>
                </ListItem>
              )}

              {permissions?.roles.some((role) => role != 'ROLE_CUSTOMER') &&
                showSideMenus()}

              {hasPermission('CustomerPortal') && 
                permissions?.roles.some((role) => role === 'ROLE_CUSTOMER') &&
                showCustomerPortalSideMenus()}
              {hasPermission('SupplierPortal') && (
                <>
                  <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                    <Tooltip title="PO Portal" arrow>
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={() => closeSidebar(9)}
                        to={`/supplier-portal/${authUserData?.associatedSupplierId}/poview`}
                        startIcon={
                          <IconHolder>
                            <FontAwesomeIcon icon={icon({name: 'desktop'})} />
                          </IconHolder>
                        }
                      ></Button>
                    </Tooltip>
                  </ListItem>
                </>
              )}              
              {authUserData &&
                authUserData?.roles?.some(
                  (d) => d?.id === 1 || d?.id === 2
                ) && (
                  <>
                    <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                      <Tooltip title="PO Change Request" arrow>
                        <Button
                          disableRipple
                          component={RouterLink}
                          onClick={() => closeSidebar(3)}
                          to={'/po-change-request'}
                          startIcon={
                            <IconHolder>
                              <FontAwesomeIcon icon={icon({name: 'file-signature'})} />
                            </IconHolder>
                          }
                        ></Button>
                      </Tooltip>
                    </ListItem>
                  </>
                )}
                
                {hasPermission('ManageSuppliers') && (
                  <ListItem sx={{ padding: '0px', marginBottom: '20px' }}>
                    <Tooltip title="Manage Suppliers" arrow>
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={() => closeSidebar(3)}
                        to={'/manage-suppliers'}
                        startIcon={
                          <IconHolder>
                            <FontAwesomeIcon icon={icon({name: 'truck-moving'})} />
                          </IconHolder>
                        }
                      ></Button>
                    </Tooltip>
                  </ListItem>
                )}
             
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
