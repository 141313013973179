import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';

import User_Setup from '../../../../assets/Icons/User_setup.svg';
import Access_Portal from '../../../../assets/Icons/Access_portal.svg';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import {
  Box,
  styled,
} from '@mui/material';

import './DataGrid.css';
import { useSelector } from 'react-redux';
import DataTable from 'src/components/DataTables/DataTable';
import SERVER_BASE_URL from 'src/config/config';

const TableContainer = styled(Box)(
  () => `
  padding: 15px 24px;
  background-color: white;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  `
);

const SalesCustomerTable = () => {

  const [salesCustomerDataTableData, setSalesCustomerDataTableData] = useState(false);
  const [salesCustomerDataTableColumns, setSalesCustomerDataTableColumns] = useState(false);
  const [salesCustomerDataTableOptions, setSalesCustomerDataTableOptions] = useState(false);


  const authUser = useSelector((state) => state?.auth?.authUserData);
  const authToken = useSelector((state) => state?.auth?.token?.token);

  const gridStyle = useMemo(
    () => ({
      height: '100%',
      width: '100%',
      margin: '12px 0'
    }),
    []
  );

  //Setting up Customer Columns
  useEffect(() => {
    let columns = [
      {
        title: "Customer Number",
        name: "customerNumber",
        data: "customerNumber"
      },
      {
        title: "Customer Name",
        name: "customerName",
        data: "customerName"
      },
      {
        title: "Associated Customer Numbers",
        name: "associatedCustomerNumbers",
        data: "associatedCustomerNumbers",
        render: function (data, type, row, meta) {
          return (data.associatedCustomerNumbers == null || data.associatedCustomerNumbers == '') ? 'N/A' : data.associatedCustomerNumbers;
        },
      },
      {
        title: "Ship to Location",
        name: "shippingLocations",
        data: "shippingLocations",
        render: function (data, type, row, meta) {
          var shipLocation = 'N/A';

          if (data != null && data.length > 0) {
            shipLocation = data[0].address1 +
              ',' +
              (data[0].address2 && data[0].address2 + ',') +
              data[0].city + ',' +
              data[0].stateCode + ',' +
              data[0].country + ',' +
              data[0].postalCode;
          }
          return shipLocation;
        },
      },
      {
        title: "Status",
        name: "status",
        data: "status",
        width: "120px",
        render: function (data, type, row, meta) {
          var backgroundColor = (data.description === 'Active') ? "#46bd60" :
            (data.description === 'Inactive') ? "#FF4A4A" :
              "#555555";
          return `
            <div>
              <span
                style="
                  border-radius: 30px;
                  text-align: center;
                  padding: 4px 20px;
                  height: 22px;
                  color: white;
                  background: ${backgroundColor};      
                  margin-top: -2px;"
              >
                ${data.description}
              </span>
            </div>`;
        },
      },
      {
        title: "Action",
        name: "action",
        data: "id",
        render: function (data, type, row, meta) {

          var customerPortalLink = (row.status.description === 'Active') ?
            `<Tooltip title="Access Portal" arrow>
              <img
                className="Img"
                disabled=${row.status.description === 'Inactive'
              ? true
              : false || row.status.description === 'Pending Setup'
                ? true
                : false
            }
                style="
                  cursor: pointer;
                  opacity:${(row.status.description === 'Inactive') || (row.status.description === 'Pending Setup') ? '0.5' : '1'
            };"
                onclick="${(row.status.description === 'Inactive' || row.status.description === 'Pending Setup') ? '' : `document.location='/customer-portal/${data}/load?cp=true';`}"
                src=${Access_Portal}
              ></img>
            </Tooltip>` :
            `
            <img
              className="Img"
              disabled=${row.status.description === 'Inactive'
              ? true
              : false || row.status.description === 'Pending Setup'
                ? true
                : false
            }
              style="
                opacity:${(row.status.description === 'Inactive') || (row.status.description === 'Pending Setup') ? '0.5' : '1'
            }"
              onclick="${(row.status.description === 'Inactive' || row.status.description === 'Pending Setup') ? '' : `document.location='/customer-portal/${data}/load?cp=true';`}"
              src=${Access_Portal}
            ></img>`;


          return `<div style=margin:"5px 0 0 0;">
          <Box>
            ${customerPortalLink}
          </Box>
        </div>`;
        }
      }
    ]
    setSalesCustomerDataTableColumns(columns);
  }, []);

  //Set up Buttons based on privileges
  const SalesCustomerDataTableButtons = () => {
    let buttons = [];
    buttons.push(
      { extend: 'copy', className: 'btn-outline-secondary' },
      { extend: 'csv', className: 'btn-outline-secondary' },
      { extend: 'print', className: 'btn-outline-secondary' }
    );
    return buttons;
  };


  //Setting up configuration
  useEffect(() => {
    let options = {
      dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
        "<'row'<'col-12'tr>>" +
        "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
      buttons: SalesCustomerDataTableButtons(),
      ajax: {
        url: `${SERVER_BASE_URL}/customer/getAllCustomersByUserId/${authUser?.id}`,
        type: "GET",
        dataSrc: function (data) {
          setSalesCustomerDataTableData(data);
          return data;
        },
        // dataSrc: 'content',
        contentType: 'application/json',
        dataType: 'JSON',
        timeout: 0,
        headers: {
          "Authorization": `Bearer ${authToken}`,
        },
      },
      info: true,
      processing: true,
      retrieve: true,
      pageResize: true,
      scrollCollapse: true,
      lengthChange: true,
      pageLength: 10,
      fixedHeader: true,
      scrollY: '450px',
      order: [[1, 'asc']],
      searching: true,
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search',
      }
    };
    setSalesCustomerDataTableOptions(options);
  }, []);

  return (
    <div className="custom-pg-content-sec">
      <Box
        className="custom-pg-titlesticky-sec"
        component="form"
        sx={{
          '& > :not(style)': { ml: 0 },
          display: { md: 'flex', xs: 'box' },
          flexDirection: 'row'
        }}
        noValidate
        autoComplete="off"
      >
        <Box className="custom-heading-container-sec" sx={{}}>
          <span
            className="custom-heading-sec"
            style={{
              fontSize: '20px',
              fontWeight: 700,
              fontWeight: 700,
              display: 'inline-flex',
              flexDirection: 'column'
            }}
          >
            Customer List
            <label
              style={{
                fontSize: '15px',
                fontWeight: '600',
                lineHeight: '1',
                marginTop: '12px'
              }}
            >
              Customer List
            </label>
          </span>{' '}
        </Box>
      </Box>
      <div className="custom-pg-tables-content-sec">
        <TableContainer style={gridStyle}>
          {salesCustomerDataTableColumns && salesCustomerDataTableOptions && (
            <DataTable
              id={'ManageSalesCustomersTable'}
              columns={salesCustomerDataTableColumns}
              options={salesCustomerDataTableOptions}
            />
          )}
        </TableContainer>
      </div>
    </div>
  );
};

export default SalesCustomerTable;
